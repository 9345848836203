<template>
  <Timeline
    title="list.name"
    :timeline="timeline"
    :list-id="listId"
    timeline-name="list"
  />
</template>

<script src="./lists_timeline.js"></script>
