<template>
  <Timeline
    :title="tag"
    :timeline="timeline"
    :timeline-name="'tag'"
    :tag="tag"
  />
</template>

<script src='./tag_timeline.js'></script>
