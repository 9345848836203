<template>
  <Timeline
    :title="$t('nav.quotes')"
    :timeline="timeline"
    :timeline-name="'quotes'"
    :status-id="statusId"
  />
</template>

<script src='./quotes_timeline.js'></script>
