export default {
  name: 'PollGraph',
  selector: '.result-fill',
  defaultRules: [
    {
      directives: {
        background: '--accent',
        opacity: 0.5
      }
    }
  ]
}
